export const variants = {
  jump: { y: ['0%', '-5%', '0%'], transition: { type: 'spring', duration: 0.55 } },
}

export const backgroundColorVariants = {
  scale: { scale: [0, 120, 0], transition: { type: 'spring', duration: 0.55 } },
}

export const tooltipVariants = {
  animate: {
    y: '0px',
    opacity: 1,
    transition: {
      y: {
        type: 'spring',
        stiffness: 170,
      },
    },
  },
  exit: {
    y: '20px',
    opacity: 0,
    transition: {
      y: {
        type: 'spring',
        stiffness: 170,
      },
    },
  },
}
