import React, { useState, useCallback, useEffect } from 'react'
import classNames from 'classnames/bind'
import { RichText } from 'prismic-reactjs'

import htmlSerializer from 'prismic/htmlSerializer'
import useTheme from 'lib/useTheme'
import useIsDesktop from 'lib/useIsDesktop'
import useUIContext from 'context/ui'
import SEO from 'components/core/SEO'
import AppearAnimation from 'components/core/AppearAnimation'
import Container from 'components/core/Container'
import { Row, Column } from 'components/core/Grid'
import Spacer from 'components/core/Spacer'
import { TooltipTrigger } from 'components/ui/Tooltip'
import Counter from 'components/ui/Counter'
import { MonumentHeading1, CircularBody } from 'components/ui/Text'
import Popup from 'components/ui/Popup'
import { SolutionsList } from 'components/ui/Solutions'

import * as s from './SolutionsPage.module.scss'
const cn = classNames.bind(s)
import { SolutionsPageTypes } from './SolutionsPageTypes'

const SolutionsPage = ({ data }: SolutionsPageTypes) => {
  const isDesktop = useIsDesktop()
  const [showExplanation, setShowExplanation] = useState<boolean>(false)
  const setViewedVoteNotification = useUIContext(s => s.setViewedVoteNotification)
  useTheme('dark', 'white')

  useEffect(() => {
    setViewedVoteNotification(true)
  }, [setViewedVoteNotification])

  /*
   * useCallback is not allowed to be called conditionally
   * and needs to be called before any early return
   */
  const renderCounter = useCallback(() => {
    const {
      data: { votes_title },
    } = data?.prismicSolutionsPage
    return (
      <div className={cn('counterContainer')}>
        <div className={cn('sticky')}>
          <Counter smallOnMobile className={cn('counter')} title={votes_title || ''} />
        </div>
      </div>
    )
  }, [data.prismicSolutionsPage])

  if (!data) return null
  const document = data.prismicSolutionsPage

  /* UI Labels and other types of textual content */
  const {
    page_meta_title,
    page_meta_description,
    page_meta_thumbnail,
    explanatory_tooltip,
    title,
    text,
    vote_explanation,
    topics,
    votes_cta,
    no_votes_tooltip,
  } = document?.data

  return (
    <AppearAnimation>
      <Container className={cn('container')}>
        <SEO title={page_meta_title} description={page_meta_description} thumbnail={page_meta_thumbnail} />

        <div className={cn('inner')}>
          {isDesktop && renderCounter()}
          <Row>
            <Column initial={6} medium={4}>
              <div className={cn('textContainer')}>
                <MonumentHeading1 scale='monumentH2'>{title?.text || ''}</MonumentHeading1>
                <Spacer initial='5.8vw' medium='2vw' />
                <CircularBody weight='book'>{text?.text}</CircularBody>
                <Spacer initial='14vw' medium='2vw' />
              </div>
              {/* Tooltip with popup explaining how the voting works */}
              {explanatory_tooltip && (
                <TooltipTrigger
                  onClick={() => setShowExplanation(true)}
                  onEnter={() => setShowExplanation(true)}
                  onLeave={() => setShowExplanation(false)}
                  title={explanatory_tooltip}
                >
                  <Popup
                    open={showExplanation}
                    onClose={() => setShowExplanation(false)}
                    variant='wide'
                    left='0'
                    translateX='0'
                  >
                    {vote_explanation && <RichText render={vote_explanation.raw} htmlSerializer={htmlSerializer} />}
                  </Popup>
                </TooltipTrigger>
              )}
            </Column>
            <Column initial={6} medium={2} />
            <Spacer initial='0' medium='4.35vw' />
          </Row>
          <div className={cn('solutions')}>
            {!isDesktop && (
              <>
                {renderCounter()}
                <Spacer initial='6vh' />
              </>
            )}
            <SolutionsList
              items={topics}
              votesCta={votes_cta || ''}
              noVotesTooltip={no_votes_tooltip}
              lang={document?.lang}
            />
          </div>
        </div>
        <Spacer initial='39vw' medium='9vw' />
      </Container>
    </AppearAnimation>
  )
}

export default SolutionsPage
