// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var container = "SolutionsPage-module--container--36P1b";
export var textContainer = "SolutionsPage-module--textContainer--2MOBu";
export var inner = "SolutionsPage-module--inner--tBuqM";
export var solutions = "SolutionsPage-module--solutions--3A__U";
export var explanation = "SolutionsPage-module--explanation--2gWos";
export var tooltip = "SolutionsPage-module--tooltip--2tkuB";
export var explanationTitle = "SolutionsPage-module--explanationTitle--286rM";
export var explanationExtended = "SolutionsPage-module--explanationExtended--v0fvy";
export var col = "SolutionsPage-module--col--2h3e1";
export var counterContainer = "SolutionsPage-module--counterContainer--1oxvp";
export var sticky = "SolutionsPage-module--sticky--3SX0h";
export var counter = "SolutionsPage-module--counter--3r_D_";