import React, { useState, useCallback, useRef } from 'react'
import classNames from 'classnames/bind'
import { motion, AnimatePresence } from 'framer-motion'

import { CircularBody, MonumentSubtitle } from 'components/ui/Text'
import ViewportEnter from 'components/core/ViewportEnter'
import Spacer from 'components/core/Spacer'
import { VoteButton } from 'components/ui/Buttons'
import { TooltipBox } from 'components/ui/Tooltip'
import useUIContext from 'context/ui'
import useIsDesktop from 'lib/useIsDesktop'

import { variants, backgroundColorVariants, tooltipVariants } from './variants'
import * as s from './SolutionsList.module.scss'
import { ItemProps } from './SolutionsListTypes'
const cn = classNames.bind(s)

const Item = ({ item, style, onClick, hasVotes, cta, noVotesTooltip }: ItemProps) => {
  const [label, setLabel] = useState<string>(cta)
  const [animate, setAnimate] = useState<boolean>(false) /* Triggers item's background animation */
  const [colorChange, setColorChange] =
    useState<boolean>(false) /* Triggers description color change needed due to background animation */
  const [isInView, setIsInView] = useState<boolean>(false)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const { votingPossible } = useUIContext(s => s.votesAnimation)

  /* Needed to automatically close noVotesTooltip on mobile */
  const isDesktop = useIsDesktop()

  const noVotesTimeout = useRef<NodeJS.Timer>()

  /* 1. Handles noVotesTooltip */
  const onMouseEnter = useCallback(() => {
    if (noVotesTimeout.current) clearTimeout(noVotesTimeout.current)
    return () => noVotesTimeout.current && clearTimeout(noVotesTimeout.current)
  }, [])

  /* 1. */
  const onMouseLeave = useCallback(() => {
    if (noVotesTimeout.current) clearTimeout(noVotesTimeout.current)
    noVotesTimeout.current = setTimeout(() => setShowTooltip(false), 1500)
    return () => noVotesTimeout.current && clearTimeout(noVotesTimeout.current)
  }, [])

  if (!item?.topic_id) return null

  const handleClick = () => {
    if (hasVotes) {
      setLabel('+1')
    } else {
      setShowTooltip(true)
      if (!isDesktop) {
        if (noVotesTimeout.current) clearTimeout(noVotesTimeout.current)
        noVotesTimeout.current = setTimeout(() => setShowTooltip(false), 3500)
      }
    }

    setAnimate(true)
    setColorChange(true)
    onClick()

    const timerId = setTimeout(() => {
      setLabel(cta)
      setAnimate(false)
    }, 550)

    const colorTimerId = setTimeout(() => setColorChange(false), 550)

    return () => {
      timerId && clearTimeout(timerId)
      colorTimerId && clearTimeout(colorTimerId)
      noVotesTimeout.current && clearTimeout(noVotesTimeout.current)
    }
  }

  return (
    <motion.li className={cn('container')} variants={variants} animate={animate && votingPossible ? 'jump' : ''}>
      <ViewportEnter onEnter={() => setIsInView(true)} threshold={0.1}>
        <section style={style} className={cn('item', { isInView })}>
          <AnimatePresence>
            {showTooltip && noVotesTooltip?.raw && (
              <motion.div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={cn('tooltip')}
                variants={tooltipVariants}
                initial='exit'
                animate='animate'
                exit='exit'
              >
                <TooltipBox>{noVotesTooltip.raw}</TooltipBox>
              </motion.div>
            )}
          </AnimatePresence>
          <Spacer initial='2rem' medium='2.38vw' />
          <div className={cn('content')}>
            <div className={cn('text')}>
              {item.topic_title && (
                <MonumentSubtitle as='h2' className={cn('title')}>
                  {item.topic_title.text}
                </MonumentSubtitle>
              )}
              {item.topic_description && (
                <CircularBody className={cn('description', { isAnimating: colorChange })} weight='book'>
                  {item.topic_description.text}
                </CircularBody>
              )}
            </div>
            <Spacer initial='5.33vw' hideOnDesktop />
            <div className={cn('buttonContainer')}>
              <VoteButton
                className={cn('button')}
                votes={`${item?.votes || 0}`}
                onClick={handleClick}
                onMouseLeave={onMouseLeave}
                votingPossible={votingPossible}
              >
                {label}
              </VoteButton>
              <motion.div
                className={cn('cover', { hasVotes: votingPossible })}
                variants={backgroundColorVariants}
                animate={animate ? 'scale' : ''}
              />
            </div>
          </div>
          <Spacer initial='2rem' medium='1.96vw' />
        </section>
      </ViewportEnter>
    </motion.li>
  )
}

export default Item
