import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from 'prismic/linkResolver'
import SolutionsPage from 'views/SolutionsPage'

import { prismicRepo } from '../../prismic-configuration'

export const query = graphql`
  query SolutionsQuery($lang: String) {
    prismicSolutionsPage(lang: { eq: $lang }) {
      _previewable
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          text
        }
        text {
          text
        }
        topics {
          topic_id
          topic_description {
            text
          }
          topic_title {
            text
          }
        }
        vote_explanation {
          raw
        }
        no_votes_tooltip {
          raw
        }
        explanatory_tooltip
        votes_title
        votes_cta
        page_meta_title
        page_meta_description
        page_meta_thumbnail {
          url
          dimensions {
            height
            width
          }
          alt
        }
      }
    }
    allPrismicFact(filter: { lang: { eq: $lang } }) {
      totalCount
    }
    prismicVideosPage(lang: { eq: $lang }) {
      _previewable
      data {
        videos {
          youtube_embed_id
        }
      }
    }
    prismicSiteSettings(lang: { eq: $lang }) {
      lang
    }
  }
`

export default withPrismicPreview(SolutionsPage, [
  {
    repositoryName: prismicRepo,
    // @ts-ignore
    linkResolver,
  },
])
