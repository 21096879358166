import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { ref, update, increment } from 'firebase/database'

import { useDatabase, useUser } from 'lib/firebase'
import useMaximumVotes from 'lib/useMaximumVotes'
import useFirebaseContext from 'context/firebase'
import useUIContext from 'context/ui'

import Item from './SolutionItem'
import { SolutionsListProps, ItemProps, SolutionProps } from './SolutionsListTypes'

const SolutionsList = ({ items, votesCta, noVotesTooltip, lang }: SolutionsListProps) => {
  const [hasScrolled, setHasScrolled] = useState<boolean>(false)
  const [order, setOrder] = useState<string[]>([])
  const totalVotes = useFirebaseContext(s => s.totalVotes)
  const triggerVotesAnimation = useUIContext(s => s.triggerVotesAnimation)
  const handleScroll = useCallback(() => setHasScrolled(true), [])

  /* Firebase */
  const database = useDatabase()
  const user = useUser()

  const maximumVotes = useMaximumVotes()
  const earnedVotes = useFirebaseContext(s => s.earnedVotes)
  const spentVotes = useFirebaseContext(s => s.spentVotes)
  const remainingVotes = useMemo(() => (earnedVotes || 0) - (spentVotes || 0), [earnedVotes, spentVotes])
  const setSpent = useFirebaseContext(s => s.setSpentVotes)
  const setTotalVotes = useFirebaseContext(s => s.setTotalVotes)

  /* @ts-ignore */
  const solutions: ItemProps[] | SolutionProps[] | [] = useMemo(() => {
    const solutions = items
    if (!solutions) return []
    if (!totalVotes) return solutions

    /* @ts-ignore */
    const populated = solutions.map(t => ({ ...t, votes: totalVotes[t?.topic_id] || 0 }))
    if (order.length) return order.map(id => populated.find(i => i?.topic_id === id)) /* Initially stored order */
    return populated.sort((a, b) => b.votes - a.votes) /* sort by votes on mount */
  }, [items, totalVotes, order])

  /* Store initial order to avoid potential re-arranging when user place their votes */
  useEffect(() => {
    if (solutions && totalVotes && !order.length) setOrder(solutions.map(s => s?.topic_id || ''))
  }, [solutions, totalVotes, order])

  useEffect(() => {
    if (typeof window === 'undefined') return
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(() => {
    if (hasScrolled) {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [hasScrolled, handleScroll])

  const vote = useCallback(
    id => {
      if (!database || !user || earnedVotes === undefined) return
      triggerVotesAnimation({ animate: true, votingPossible: remainingVotes !== 0 })

      const spent = spentVotes || 0

      /* Exit early if user has already spent their votes */
      if (spent >= earnedVotes || spent >= maximumVotes) return

      // @ts-ignore
      setTotalVotes({ ...totalVotes, [id]: (totalVotes?.[id] || 0) + 1 })
      setSpent(spent + 1)
      update(ref(database), { [`requestedVoting/${lang}/${id}`]: increment(1) })
    },
    [
      database,
      user,
      spentVotes,
      setSpent,
      setTotalVotes,
      triggerVotesAnimation,
      earnedVotes,
      maximumVotes,
      totalVotes,
      remainingVotes,
      lang,
    ],
  )

  return (
    <ul>
      {solutions.map((i, index: number) => (
        <Item
          cta={votesCta}
          noVotesTooltip={noVotesTooltip}
          hasVotes={!!remainingVotes}
          onClick={() => vote(i?.topic_id)}
          item={i}
          key={`topic-${index}`}
          style={{ transitionDelay: hasScrolled ? '0.2s' : `${(index + 1) * 0.2}s` } as React.CSSProperties}
        />
      ))}
    </ul>
  )
}

export default SolutionsList
