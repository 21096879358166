// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var container = "SolutionsList-module--container--2Qser";
export var item = "SolutionsList-module--item--1RbdB";
export var isInView = "SolutionsList-module--isInView--3ctxx";
export var content = "SolutionsList-module--content--3hz0e";
export var title = "SolutionsList-module--title--3ltPm";
export var description = "SolutionsList-module--description--2TH9v";
export var isAnimating = "SolutionsList-module--isAnimating--3-dgz";
export var tooltip = "SolutionsList-module--tooltip--3byt7";
export var buttonContainer = "SolutionsList-module--buttonContainer--1ecY5";
export var cover = "SolutionsList-module--cover--3g9wF";
export var hasVotes = "SolutionsList-module--hasVotes--MD7e7";